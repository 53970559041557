import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Article from "../components/article"

export const postQuery = graphql`
  query BlogPost($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      frontmatter {
        author
        date
        title
        path
      }
      html
    }
  }
`

export default function Template({ data }) {
  const post = data.markdownRemark
  const { title, date } = post.frontmatter

  return (
    <Layout>
      <SEO title={title} />
      <div style={{ marginBottom: "12px" }}>
        <Link to="/blog">&lt; All Articles</Link>
      </div>
      <h1>{title}</h1>
      <div
        style={{ color: "#cccccc", fontWeight: "bolder", marginBottom: "12px" }}
      >
        {date}
      </div>
      <Article>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
      </Article>
    </Layout>
  )
}
